import React from 'react';

export interface IComponentProps {
    className?: string
    schedule: string
    serviceName: string
    logo: string
    style?: React.CSSProperties
}

export default function Header(props: IComponentProps ) {
    return (
        <header className={"border-b p-4 " + props.className || ''} style={props.style}>
            <nav className="flex items-center font-medium win-pc">
                <img className="mx-2" src={props.logo} width='30px' alt='logo'/>
                <h1 className="text-white">{props.serviceName}</h1>
                <svg className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                </svg>
                <h1 className="text-yellow-600">{props.schedule}</h1>
            </nav>
            <div className="win-mobile">{props.serviceName}</div>
        </header>
    )
}