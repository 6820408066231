import localForage from "localforage";

const FINGERPRINT = "fingerprint";
const HISTORY = "history.v2.4.0";

export function getItem(key: string) {
    return localForage.getItem(key);
}

export function setItem(key: string, value: any) {
    return localForage.setItem(key, value);
}

export function removeItem(key: string) {
    return localForage.removeItem(key);
}

// getLocalFingerprint 获取当前设备指纹
export function getLocalFingerprint(): Promise<string> {
    return getItem(FINGERPRINT) as Promise<string>
}

// setLocalFingerprint 将设备指纹写入硬盘
export function setLocalFingerprint(cert: string | null) {
    if (cert == null) return removeItem(FINGERPRINT);
    return setItem(FINGERPRINT, cert as string);
}

export function setHistoryMessages(msg: any) {
    return setItem(HISTORY, msg);
}

export async function appendHistoryMessages(msg: any) {
    const history = await getHistoryMessages() as any[] || [];
    if (history) {
        history.push(msg)
        return setItem(HISTORY, history);
    }
    return setItem(HISTORY, msg);
}

export function getHistoryMessages(): Promise<any[]> {
    return getItem(HISTORY) as Promise<any[]>;
}

export async function removeHistoryMessage(messageId: number) {
    try{
        const messages = await getHistoryMessages();
        await setHistoryMessages(messages.filter(v => v.data.message_id !== messageId));
    } catch (e) {

    }
}