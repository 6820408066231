// Copyright 2020 The upchat. All rights reserved.
// 这是用户评价组件

import React from "react";

export interface OpinionParams {
    score: number // 评价的分数
    content: string // 评价的内容
}

interface IComponentProps {
    style?: React.CSSProperties
    className?: string
    handleSubmit?: (data: IComponentState) => void
    handleCancel?: any
}

interface IComponentState extends OpinionParams { }

// Opinion 用户评价组件, 被提交时调用 props.handleSubmit
export default class OpinionForm extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            score: 10,
            content: ''
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        if (!this.props.handleSubmit) {
            return;
        }
        this.props.handleSubmit(Object.assign({}, this.state));
        this.setState({ score: 10, content: '' })
    }

    render() {
        return (
            <form
                style={this.props.style}
                className={`bg-white flex flex-col text-base justify-center items-center p-4 w-full ${this.props.className} text-gray-800`}
                onSubmit={e => e.preventDefault()}
            >
                <h1 className="text-xl font-bold"><span role="img" aria-label="flow">🌹</span> 满意度评价</h1>

                <div className="flex items-center space-x-4">
                    <div className="space-x-1">
                        <input type="radio" name="score" onChange={e => this.setState({ score: 10 })} />
                        <label>非常满意</label>
                    </div>
                    <div className="space-x-1">
                        <input type="radio" name="score" defaultChecked onChange={e => this.setState({ score: 7 })} />
                        <label>满意</label>
                    </div>
                    <div className="space-x-1">
                        <input type="radio" name="score" onChange={e => this.setState({ score: 5 })} />
                        <label>一般</label>
                    </div>
                    <div className="space-x-1">
                        <input type="radio" name="score" onChange={e => this.setState({ score: 3 })} />
                        <label>不满意</label>
                    </div>
                </div>

                <textarea
                    className="w-full border border-gray-500 focus:border-blue-500 rounded p-2 h-32 resize-none"
                    value={this.state.content}
                    onChange={e => this.setState({ content: e.target.value })}
                    placeholder="评价内容"
                />

                <div className="flex w-full justify-center">
                    <button 
                        className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
                        onClick={this.handleSubmit}
                    >
                        提交
                    </button>

                    {this.props.handleCancel
                        ? <button
                            className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
                            onClick={this.props.handleCancel}
                        >取消</button>
                        : null
                    }
                </div>

            </form>
        )
    }
}