// Copyright 2020 The upchat. All rights reserved.
// 反馈类型表单

import React from "react";

interface IComponentProps {
    style?: React.CSSProperties
    className?: string
    handleSubmit?: (data: any) => void
    handleCancel?: any
}

export const AdvisoryType = {
    'CDN': 'CDN',
    '云存储': '云存储',
    '云处理': '云处理',
    '厚德云主机': '厚德云主机',
    '其他问题': '其他问题',
}

// AdvisoryTypeForm 填写提交用户信息表单
export default class AdvisoryTypeForm extends React.Component<IComponentProps> {

    private handleSubmit = (advtype: string) => {
        if (this.props.handleSubmit) {
            this.props.handleSubmit(advtype);
        }
    }

    render() {
        return (
            <div
                style={this.props.style}
                className={`bg-white p-3 w-full text-base ${this.props.className}`}
            >
                <h1 className="font-bold my-2 text-gray-800">
                    您可以优先通过查看
                    <a className="text-blue-500"href="https://help.upyun.com/docs/faq-faq/">《FAQ 手册》</a>
                    快速解决问题。
                </h1>
                <h1 className="font-bold my-2 text-gray-800">请选择咨询类型: </h1>
                <div className="grid grid-cols-5 gap-1 text-gray-100 text-xs">
                    <button
                        className="bg-blue-500 rounded-lg py-1 focus:outline-none"
                        onClick={e => this.handleSubmit(AdvisoryType.CDN)}
                    >{AdvisoryType.CDN}</button>
                    <button
                        className="bg-blue-500 rounded-lg focus:outline-none"
                        onClick={e => this.handleSubmit(AdvisoryType.云存储)}
                    >{AdvisoryType.云存储}</button>
                    <button
                        className="bg-blue-500 rounded-lg focus:outline-none"
                        onClick={e => this.handleSubmit(AdvisoryType.云处理)}
                    >{AdvisoryType.云处理}</button>
                    <button
                        className="bg-blue-500 rounded-lg px-2 focus:outline-none"
                        onClick={e => this.handleSubmit(AdvisoryType.厚德云主机)}
                    >{AdvisoryType.厚德云主机}</button>
                    <button
                        className="bg-blue-500 rounded-lg focus:outline-none"
                        onClick={e => this.handleSubmit(AdvisoryType.其他问题)}
                    >{AdvisoryType.其他问题}</button>
                </div>
            </div>
        )
    }
}