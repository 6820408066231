import { Config } from './interface';

const config : Config = {
    header: {
        logo: 'favicon-upyun.ico',
        content:'又拍云客服',
    },
    screen:{},
    serviceURL:'https://service-api.upyun.com',
    websocketURL: 'wss://service-api.upyun.com/ws/1',
    bulletin: {
        content: '尊敬的客户，感谢您使用又拍云客服。为保证服务及时高效，请您提供准确的账号或服务名、有效的联系方式',
        worktime:'工作时间: (9:00-18:00)',
        helper: [
            {
                content: '文档中心',
                url: 'https://help.upyun.com/'
            },
            {
                content: '视频教程',
                url: 'https://help.upyun.com/knowledge-base/850'
            },
            {
                content:'工单',
                url:'https://console.upyun.com/#/dashboard'
            }
        ]
    }
}

export default config;