import React from "react";
import BubbleContent from "./BubbleContent"
import { Mode, IMessage } from "../upchat-sdk/message";

export interface BubbleProps {
    message: IMessage
    showName?: boolean
    showAvatar?: boolean
    showBubbleBackground?: boolean
    imageMaxWidth?: string
    avatarSize?: string
    fontSize?: string
    split?: boolean
    HiddenDelete?: boolean // 是否隐藏被撤回的信息
    hiddenPlugins?: boolean
    handleSubmitOpinion?: (data: Record<'score', number> & Record<'content', string | null>) => any
    handleSubmitUserInfo?: (data: string)=> any;
    handleSubmitFeedback?: any
    handleSubmitAdvisoryType?: any
}

function SystemBubble(props: BubbleProps & Record<"content", JSX.Element | string>) {
    return (
        <div className="p-2">
            <p className="text-center text-red-600 whitespace-pre-wrap break-all" style={{ fontSize: props.fontSize }}>
                {props.content}
            </p>
        </div>
    )
}

function DeletedBubble(props: BubbleProps & Record<"content", JSX.Element | string>) {
    return (
        <div className="max-w-md mx-auto my-3 rounded-md">
            <p className="text-gray-600 text-center whitespace-pre-wrap break-all" style={{ fontSize: props.fontSize }}>
                客服撤回了一条消息
            </p>
        </div>
    )
}

function LeftBubble(props: BubbleProps & Record<"content", JSX.Element | string>) {
    const bubbleClassName = props.showBubbleBackground ?
        'flex rounded-md bg-gray-200 mx-2 text-lg text-gray-800' :
        'flex text-lg text-gray-800';
    const extraClassName = props.message.data.deleted_at ? "bg-red-200" : "";
    const ref = React.createRef<HTMLDivElement>();

    return (
        <div className="mx-2 my-2">
            <div className="text-orange-500"> {props.showName ? props.message.source.name : null}</div>
            <div className="flex">
                {props.showAvatar ? <img style={{ width: props.avatarSize, height: props.avatarSize }} src={props.message.source.avatar} alt='' /> : null}
                <div className={bubbleClassName + " " + extraClassName} ref={ref}>
                    {props.content}
                </div>
            </div>
        </div>
    )
}

function RightBubble(props: BubbleProps & Record<"content", JSX.Element | string>) {
    const bubbleClassName = props.showBubbleBackground ?
        'flex rounded-md text-lg bg-blue-500 mx-2 text-white' :
        'flex text-lg text-gray-800';
    const extraClassName = props.message.data.deleted_at ? "bg-red-200" : "";

    const ref = React.createRef<HTMLDivElement>();
    return (
        <div className="mx-2 my-2">
            <div className={"text-green-500" + (props.split === false ? "" : " text-right")}> 
            {props.showName ? props.message.source.name : null}
            </div>
            <div className={"flex" + (props.split === false ? "" : " flex-row-reverse")}>
                {props.showAvatar ? <img style={{ width: props.avatarSize, height: props.avatarSize }} src={props.message.source.avatar} alt='' /> : null}
                <div className={bubbleClassName + " " + extraClassName} ref={ref}>
                    {props.content}
                </div>
            </div>
        </div>
    )
}

// Bubble 信息气泡显示
export default function Bubble(props: BubbleProps) {
    if (props.message.data.deleted_at && props.HiddenDelete) {
        return <DeletedBubble {...props} content={props.message.data.content} />;
    }

    if (props.message.data.mode === Mode.O_SYSTEM) {
        return <SystemBubble {...props} content={props.message.data.content} />;
    }

    if (props.message.source.role === 'waiter') {
        return <LeftBubble {...props} content={<BubbleContent {...props} />} />;
    }

    return <RightBubble {...props} content={<BubbleContent {...props} />} />;
}