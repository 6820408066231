import React from 'react';
import EmojiPicker from './EmojiPicker';
import Dialog from './Dialog';
import OpinionForm from './plugins/OpinionForm';
import UserInfoForm from './plugins/UserInfoForm';
import FeedbackForm from './plugins/FeedbackForm';
import ProblemFrom from './plugins/ProblemFrom';
export interface IComponentProps {
    className?: string
    handleSubmitFile?: any

    openEmojiPicker: boolean
    toggleOpenEmojiPicker: () => any

    openOpinionForm: boolean
    toggleOpenOpinionForm: () => any

    openUserInfoForm: boolean
    toggleOpenUserInfoForm: () => any

    openFeedbackForm: boolean
    toggleOpenFeedbackForm: () => any

    openProblemForm: boolean
    toggleOpenProblemForm: ()=> any

    handlePick: (data: string) => any
    handleSubmitOpinion: (data: any) => any
    handleSubmitUserInfo: (data: any) => any
    handleSubmitFeedback: (data: any) => any
    handleSubmitProblem: (data: any) => any
}


export default class EditorTools extends React.Component<IComponentProps> {

    submitFile(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.currentTarget.files && e.currentTarget.files?.length > 0) {
            this.props.handleSubmitFile(e.currentTarget.files[0]);
        }
        e.currentTarget.value = '';
        e.currentTarget.files = null;
    }

    render() {

        return (
            <>
                {/* 评价窗口 */}
                <Dialog 
                    open={this.props.openOpinionForm} 
                    onClose={() => {}}
                >
                    <div className="w-96 border bg-white">
                        <OpinionForm
                            key="opinion"
                            handleCancel={() => this.props.toggleOpenOpinionForm()}
                            handleSubmit={data => {
                                this.props.handleSubmitOpinion(data);
                                this.props.toggleOpenOpinionForm();
                            }}
                        />
                    </div>
                </Dialog>

                {/* 用户信息填写窗口 */}
                <Dialog
                    open={this.props.openUserInfoForm}
                    onClose={() => {}}
                >
                    <div className="w-96 border bg-white">
                        <UserInfoForm
                            key="userinfo"
                            handleCancel={() => this.props.toggleOpenUserInfoForm()}
                            handleSubmit={data => {
                                this.props.handleSubmitUserInfo(data);
                                this.props.toggleOpenUserInfoForm();
                            }}
                        />
                    </div>
                </Dialog>

                {/* 反馈建议 */}
                <Dialog
                    open={this.props.openFeedbackForm}
                    onClose={() => {}}
                >
                    <div className="w-96 border bg-white">
                        <FeedbackForm
                            key="feedback"
                            handleCancel={() => this.props.toggleOpenFeedbackForm()}
                            handleSubmit={data => {
                                this.props.handleSubmitFeedback(data);
                                this.props.toggleOpenFeedbackForm();
                            }}
                        />
                    </div>
                </Dialog>

                {/* 其他问题 */}
                <Dialog
                    open={this.props.openProblemForm}
                    onClose={() => {}}
                >
                    <div className="w-96 border bg-white">
                        <ProblemFrom
                            key="problem"
                            handleCancel={() => this.props.toggleOpenProblemForm()}
                            handleSubmit={data => {
                                this.props.handleSubmitProblem(data);
                                this.props.toggleOpenProblemForm();
                            }}
                        />
                    </div>
                </Dialog>

                <div className={"msg-tools-box flex border-t border-gray-300 bg-gray-100 p-2 " + this.props.className || ''}>
                    <EmojiPicker
                        style={{ display: this.props.openEmojiPicker ? 'block' : 'none' }}
                        handlePick={d => this.props.handlePick(d)}
                    />

                    {/* 打开表情包拾取窗口 */}
                    <label title="表情包" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }} onClick={this.props.toggleOpenEmojiPicker}>
                        <svg className="fill-current w-5 h-5 mx-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
                        </svg>
                    </label>

                    {/* 发送图片 */}
                    <input className="hidden" id="image-uploader" type="file" name="file" accept="image/jpeg,image/png" onChange={e => this.submitFile(e)} />
                    <label htmlFor="image-uploader" title="发送图片" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }}>
                        <svg className="fill-current w-5 h-5 mx-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm11 9l-3-3-6 6h16l-5-5-2 2zm4-4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                        </svg>
                    </label>

                    {/* 发送文件 */}
                    <input className="hidden" id="file-uploader" type="file" name="file" accept="*" onChange={e => this.submitFile(e)} />
                    <label htmlFor="file-uploader" title="发送文件" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }}>
                        <svg className="fill-current w-5 h-5 mx-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" />
                        </svg>
                    </label>

                    {/* 评价 */}
                    <label title="评价" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }} onClick={this.props.toggleOpenOpinionForm}>
                        <svg className="fill-current w-5 h-5 mx-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                    </label>

                    {/* 用户信息 */}
                    <label title="用户信息" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }} onClick={this.props.toggleOpenUserInfoForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                    </label>

                    {/* 反馈建议 */}
                    <label title="反馈建议" style={{ display: this.props.openEmojiPicker ? 'none' : 'block' }} onClick={this.props.toggleOpenFeedbackForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                        </svg>
                    </label>
                </div >
            </>
        )
    }
}
