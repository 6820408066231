
function makeTitleFlasherContent(){
    const result: string[] = [];
    const title = window.document.title + ' —-您有新的未读消息🔥';
    for (let i = 0; i < title.length-1; i += 1) {
        result.push(title.substring(i));
    }
    return result
}

export class TitleFlasher {

    private readonly titleContent =  makeTitleFlasherContent();
    private readonly originTitleContent: string = window.document.title;  
    private timer: number = -1;
    private index: number = 0;

    private shine = () => {
        if (this.index >= this.titleContent.length) this.index = 0;
        window.document.title = this.titleContent[this.index];
        this.index++;
    }

    noisy() {
        // window.document.removeEventListener('focus', top);
        const timer = window.setInterval(this.shine, 200);
        window.addEventListener("focus", ev => {
            window.clearInterval(timer);
            window.document.title = this.originTitleContent;
        });
    }
}