import { Profile }from './profile';

export enum Mode {
    O_SYSTEM = 0B0000,
    O_NORMAL = 0B0001,
    O_GREET = 0B0010,
    O_PLUGINS_INVITE = 0B0011,
    O_PLUGINS_USERINFO = 0B0100,
    O_PLUGINS_FEEDBACK = 0B0101,
    O_PLUGINS_ADVISORYTYPE = 0B0110,
}

export enum MIME {
    "text/plain" = "text/plain",
    "text/html" = "text/html",
    "image/gif" = "image/gif",
    "image/png" = "image/png",
    "image/jpeg" = "image/jpeg",
    "application/octet-stream" = "application/octet-stream",
}

export interface IMessage {
    source: Profile
    destination: Profile
    data: Data
}

export interface Data {
    mode?: Mode
    channel_id?: number
    message_id?: number
    created_at?: string
    deleted_at?: string
    content_type: MIME
    content: string
}

const sysSource = {
    id: 0,
    email:"system.upchat@upai.com",
    name: 'system',
    role: 'system',
    avatar: 'system.png',
    environment: null
}

export function createSysMessage(destination: any, content: string) {
    return { source: sysSource, destination: destination, data: { content_type: "text", content, mode: 0 } }
}