// Copyright 2020 The upchat. All rights reserved.
// 这是用户信息输入表单

import React from 'react';

export interface IComponentState {
  userInfo: string; // 用户信息
  problem: string;
}

interface IComponentProps {
  style?: React.CSSProperties;
  className?: string;
  handleSubmit?: (data: Record<'userInfo' | 'problem', string>) => void;
  handleCancel?: any;
}

// ProblemFrom 填写提交用户问题描述
export default class ProblemFrom extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      userInfo: '',
      problem: '',
    };
  }

  private handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!this.props.handleSubmit) {
      return;
    }
    this.props.handleSubmit({
      userInfo: this.state.userInfo,
      problem: this.state.problem,
    });
  };

  render() {
    return (
      <form
        style={this.props.style}
        className={`bg-white flex flex-col text-base justify-center px-10 py-6 ${this.props.className} text-gray-800`}
        onSubmit={(e) => e.preventDefault()}
      >
        <h1 className="text-xl font-bold my-2 text-gray-800 text-center">
          其他问题咨询
        </h1>
        <label className="p-1">咨询问题</label>
        <textarea
          className="w-full border border-gray-500 focus:border-blue-500 rounded p-2 h-32 resize-none"
          value={this.state.problem}
          onChange={(e) => this.setState({ problem: e.target.value })}
          placeholder="咨询内容"
        />

        <label className="p-1">用户信息（未注册用户可不填）</label>
        <input
          className="w-full h-12 border focus:border-blue-500 rounded p-2 px-8"
          value={this.state.userInfo}
          onChange={(e) => this.setState({ userInfo: e.target.value })}
          placeholder="请填写用户名/邮箱/主页地址"
        />

        <div className="flex w-full justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
            onClick={this.handleSubmit}
          >
            确认
          </button>

          {this.props.handleCancel ? (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
              onClick={this.props.handleCancel}
            >
              取消
            </button>
          ) : null}
        </div>
      </form>
    );
  }
}
