import axios from 'axios';
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import * as forage from './forage';
import { createSysMessage, Data, IMessage, MIME, Mode } from './message';
import { Profile } from './profile';

export type UpchatMessage = IMessage;
export type MessageMode = Mode;

type Observer = (client: UpchatClient) => void;

function createFormData(file: File | Blob) {
    const result = new FormData();
    result.append('file', file);
    return result;
}

interface UpchatClientOptions {
    websocketURL: string
    serviceURl: string
}

export class UpchatClient {
    constructor(options: UpchatClientOptions) {
        this.serviceURl = options.serviceURl;
        this.websocketURL = options.websocketURL;
    }

    connect = async () => {
        // 将本地的用户数据同步到内存中
        await this.profile.sync();
        this.io = io(this.websocketURL, {
            transports: ['websocket', 'polling'],
            query: {
                fingerprint: this.profile.fingerprint!,
                environment: this.profile.environment!,
            }
        });
        this.io?.on('connect', this.ioDidConnect)
        this.io?.on('disconnect', this.ioDidDisconnect);
        this.io?.on('sysret.match', this.sysretMatched);
        this.io?.on('sysret.exit', this.sysretExit);
        this.io?.on('sysret.beMoved', this.sysretBeMoved);
        this.io?.on("sysret.sync", this.sysretSync);
        this.io?.on("sysret.alert", this.alert);
        // TODO 
        this.io?.on("sysret.delete", this.onMessageDelFunc);
        this.io?.on('message', this.onMessageFunc);
    }

    io: Socket | null = null
    readonly websocketURL: string
    readonly serviceURl: string
    readonly observers: Observer[] = []

    // 用户信息
    profile: Profile = new Profile();
    remoteWaiter: any

    // 会话id
    channelId: number = 0;

    // 接收信息时触发
    private onMessageFunc: any
    private onMessageDelFunc: any

    sendMessageCount: number = 0;

    onMessage(fn: (types: 'direct' | 'backflow', message: UpchatMessage) => any) {
        this.onMessageFunc = fn;
    }

    onMessageDel(fn: (messageId: number) => any) {
        this.onMessageDelFunc = fn;
    }

    // @ts-ignore
    send = (data: Data) => {
        if (!this.io?.connected) {
            return alert('已断开连接，请刷新页面')
        }
        if (!this.remoteWaiter || !this.channelId) {
            return alert('当前没有客服，请刷新页面重新连接')
        }
        this.sendMessageCount++;
        data.created_at = new Date().toISOString();
        data.mode = Mode.O_NORMAL;
        data.channel_id = this.channelId;
        const destSocketId = this.remoteWaiter.socketId;
        this.io?.emit('channels.messages.add', { data, destSocketId });
    }

    sendFile = async (file: File | Blob) => {
        const res = await this.upload(file);
        if (!res || !res.data || !res.data.url) return;
        this.send({
            content_type: file.type as MIME,
            content: res.data.url,
        });
    }

    // upload 将文件上传到云存储空间，返回它的url
    upload(file: File | Blob): Promise<{ data: Record<"url", string> }> {
        return axios.post(`${this.serviceURl}/upload`,
            createFormData(file),
            {
                headers: {
                    'socket-id': this.io?.id,
                }
            });
    }

    private ioDidDisconnect = () => {
        if (!this.onMessageFunc) return;
        this.onMessageFunc(
            "direct",
            createSysMessage(this.profile, `系统提示：非常抱歉，网络异常，您已断开连接... - ${new Date().toLocaleString('zh', { hour12: false })}`)
        );
    }

    private ioDidConnect = () => {
        // @ts-ignore
        // 设置全局socketId
        this.checkTimeOut();
        this.publish();
    }

    private alert(msg: string) {
        return alert(msg);
    }

    // 订阅函数
    subscribe(fn: Observer) {
        this.observers.push(fn);
    }

    // 触发订阅函数
    publish = () => {
        this.observers.forEach(v => v(this));
    }

    get connectStatus() {
        if (!this.io?.connected) return "未连接";
        if (!this.remoteWaiter) return "等待客服"
        return "客服-" + this.remoteWaiter.name;
    }

    loadHistoryMessages() {
        return forage.getHistoryMessages();
    }

    // 评价
    syscallSubmitOpinion(data: Record<'score', number> & Record<'content', string | null>) {
        this.io?.emit("channels.opinions.add", { opinion_score: data.score, opinion_content: data.content });

        if (data.content) {
            setTimeout(() => this.send({
                content_type: MIME['text/plain'],
                content: '用户评价内容: ' + data.content,
            }), 500);
        }
    }

    // 提交用户信息表单
    syscallSubmitUserInfo = (data: string) => {
        this.io?.emit("users.anonymous.set", { id: this.profile.id, nickname: data });
        setTimeout(() => this.send({
            content_type: MIME['text/plain'],
            content: '用户信息: ' + data,
        }), 500);
    }

    syscallSubmitAdvisoryType = (data: string) => {
        this.send({
            content_type: MIME['text/plain'],
            content: data,
        });
        this.io?.emit("channels.ashtype.set", { channelId: this.channelId, askType: data });
    }

    // 反馈建议
    syscallSubmitFeedback = (data: any) => {
        console.log(data)
        if (!this.channelId) return;

        const {
            files,
            userinfo,
            description,
            contact,
        } = data;

        this.send({
            content_type: MIME['text/plain'],
            content: `问题及建议: ${description}\n用户信息: ${userinfo}\n联系方式: ${contact}`,
        });
        for (const file of files) {
            this.sendFile(file);
        }
    }

    syscallSubmitProblem(data: any) {
        const content = [
            '用户信息: ' + data.userInfo,
            '问题描述：' + data.problem,
        ]
        return this.send({
            content_type: MIME['text/plain'],
            content: content.join('\n'),
        });
    }

    // 处理未发生信息超时
    private checkTimeOut() {
        setTimeout(() => {
            if (!this.io?.connected || !this.onMessageFunc || this.sendMessageCount > 0) return;
            this.io?.disconnect();
            this.onMessageFunc(
                "direct",
                createSysMessage(this.profile, `系统提示：长时间未发送信息，已断开连接(刷新页面重连) - ${new Date().toLocaleString()}`)
            );
        }, 120000);
    }

    // 处理会话被转接
    private sysretBeMoved = (source: any, dest: any) => {
        this.remoteWaiter = dest;
    }

    // 处理客服离线
    private sysretExit = (user: any) => {
        if (this.remoteWaiter?.socketId === user.socketId) {
            this.remoteWaiter = null;
            this.channelId = 0;
        }
    }

    // 处理顾客信息同步
    private sysretSync = (profile: any, _: any) => {
        this.profile.save(profile);
    }

    // 被匹配时触发
    private sysretMatched = (channelId: number, profile: any) => {
        this.remoteWaiter = profile;
        this.channelId = channelId;
        this.publish();
    }
}
