import { AudioPlayer } from './notify_audio';
import { TitleFlasher } from './notify_title';

interface Interface {
    noisy(): void
}

const audio: Interface = new AudioPlayer()
const title: Interface = new TitleFlasher();

// soundNoisy 声音通知
export function soundNoisy() {
    return audio.noisy();
}

// titleNotify 标题闪烁通知
export function titleNotify() {
    return title.noisy();
}