import React from 'react';
import Dialog from './Dialog';

const MAX_INPUT_LENGTH = 500;

export interface IComponentProps {
    className?: string
    code: string
    showMobileTools: boolean
    toggleMobileTool: () => void
    handleChange(code: string): any
    handleSubmit(code: string): any
    handleSubmitFile(file: File): any
}

interface IComponentState {
    openPreviewDiglog: boolean
    openPreviewFile: File | null
    openPreviewURL: string
}
export default class Editor extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            openPreviewDiglog: false,
            openPreviewFile: null,
            openPreviewURL: '',
        }
    }

    // Enter 发送信息
    handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!event.ctrlKey && event.key === "Enter" && this.props.handleSubmit) {
            event.preventDefault();
            this.handleSubmit()
        }
    }

    // 发送信息
    handleSubmit = () => {
        const code = this.props.code;
        if (code.trim().length > 0) {
            this.props.handleSubmit(code);
        }
        this.props.handleChange('');
    }

    handleClipboardEvent = (event: React.ClipboardEvent) => {
        const text = event.clipboardData.getData('text/plain');
        if (text && text.length > 0) {
            return
        }

        const file = event.clipboardData.files[0];
        if (!file || !["image/png", "image/jpeg", "image/gif"].includes(file.type)) {
            return
        }

        this.setState({
            openPreviewDiglog: true,
            openPreviewFile: file,
            openPreviewURL: URL.createObjectURL(file)
        });
    }

    render() {
        return (
            <>
                <div className="flex win-mobile msg-mobile-box">
                    <span onClick={() => this.handleSubmit()} className="iconfont icon-send"></span>
                    <span onClick={this.props.toggleMobileTool} className={`iconfont ${this.props.showMobileTools ? 'icon-minus-circle' : 'icon-jiahao'}`}></span>
                </div>
                <div className={"bg-gray-50 msg-input-box " + this.props.className || ''}>

                    <Dialog open={this.state.openPreviewDiglog}>
                        <div className="p-2 bg-white" >
                            <div style={{ maxHeight: '400px', maxWidth: '600px', overflow: 'hidden' }}>
                                <img style={{ height: '100%' }} src={this.state.openPreviewURL} alt='img' />
                            </div>
                            <div className="flex justify-between p-2">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => this.setState({ openPreviewDiglog: false })}
                                >取消</button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => {
                                        this.setState({ openPreviewDiglog: false });
                                        this.props.handleSubmitFile(this.state.openPreviewFile!)
                                    }}
                                >发送</button>
                            </div>
                        </div>
                    </Dialog>

                    <textarea
                        rows={3}
                        placeholder="回复一下吧~"
                        className="w-full p-2 px-5 bg-gray-50 focus:outline-none resize-none f-normal"
                        value={this.props.code}
                        onChange={e => this.props.handleChange(e.currentTarget.value)}
                        onKeyPress={this.handleKeyPress}
                        onPaste={this.handleClipboardEvent}
                        maxLength={MAX_INPUT_LENGTH}
                    />
                    <div className="flex flex-row-reverse p-2 items-center win-pc ">
                        <button
                            className="btn-send border px-3 py-1 rounded focus:outline-none"
                            onClick={() => this.handleSubmit()}
                        >发送</button>
                        <div className="text-xs m-2 text-gray-500 ">
                            <span>{this.props.code.length}</span>
                            <span>/</span>
                            <span>{MAX_INPUT_LENGTH}</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
