import React from 'react';

interface IComponentProps{
    open?: boolean
    onClose?: any
}

export default class Dialog extends React.Component<IComponentProps> {

    ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

    render(){
        return (
            <div
                className={this.props.open ? 'flex items-center justify-center absolute h-full fixed w-full inset-0 bg-black bg-opacity-20' : 'hidden'}
                onClick={e => {
                    if (e.target === e.currentTarget) this.props.onClose()
                } }
            >
                <div ref={this.ref}>
                    {this.props.children}
                </div>
            </div>
        ) 
    }
}