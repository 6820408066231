import React from 'react';
import { IMessage } from '../upchat-sdk/message'
import Bubble from './Bubble';

interface OpinionParams {
    score: number
    content: string | null
}
export interface IComponentProps {
    className?: string
    style?: React.CSSProperties
    messages: IMessage[]
    handleSubmitOpinion?: (data: OpinionParams) => void
    handleSubmitUserInfo?: (data: string) => void
    handleSubmitFeedback?: (data: any) => any
    handleSubmitAdvisoryType?: (data: string) => void
}

interface IComponentState {
    preMessageLen: number
}

export default class Screen extends React.Component<IComponentProps, IComponentState> {
    private tty: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            preMessageLen: 0
        }
    }

    scrollToBottom() {
        if (this.tty && this.tty.current) {
            this.tty.current.scrollTop = this.tty.current.scrollHeight;
        }
    }

    componentDidUpdate() {
        if (this.props.messages.length !== this.state.preMessageLen) {
            this.setState({ preMessageLen: this.props.messages.length });
            this.scrollToBottom();
        }
    }

    render() {
        return (
            <div className={"bg-gray-50 " + this.props.className || ''} style={this.props.style} ref={this.tty}>
                {this.props.messages.map((v, i) => (
                    <Bubble
                        key={'m' + i + v.data.message_id}
                        message={v}
                        showName={false}
                        showAvatar={true}
                        showBubbleBackground={true}
                        imageMaxWidth={'80%'}
                        fontSize={'14px'}
                        avatarSize={"40px"}
                        HiddenDelete={true}
                        hiddenPlugins={false}
                        split={true}
                        handleSubmitOpinion={this.props.handleSubmitOpinion}
                        handleSubmitUserInfo={this.props.handleSubmitUserInfo}
                        handleSubmitFeedback={this.props.handleSubmitFeedback}
                        handleSubmitAdvisoryType={this.props.handleSubmitAdvisoryType}
                    />))}
            </div>
        )
    }
}