// Copyright 2020 The upchat. All rights reserved.
// 这是用户信息输入表单

import React from "react";

export interface IComponentState {
    userinfo: string // 用户信息
    errorMsg: string | null
}

interface IComponentProps {
    style?: React.CSSProperties
    className?: string
    handleSubmit?: (data: string) => void
    handleCancel?: any
}

// UserInfoForm 填写提交用户信息表单
export default class UserInfoForm extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            userinfo: '',
            errorMsg: null,
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        this.setState({ errorMsg: '' });

        if (!this.props.handleSubmit) {
            return;
        }

        if (!this.state.userinfo) {
            this.setState({ errorMsg: '请先填写用户信息' });
            return;
        }
        this.props.handleSubmit(this.state.userinfo);
    }

    render() {
        return (
            <form
                style={this.props.style}
                className={`bg-white flex flex-col text-base justify-center items-center px-10 py-6 ${this.props.className} text-gray-800`}
                onSubmit={e => e.preventDefault()}
            >
                <h1 className="text-xl font-bold my-2 text-gray-800 px-8">用户信息填写</h1>

                <input
                    className="w-full h-12 border focus:border-blue-500 rounded p-2 px-8"
                    style={
                        { borderColor: this.state.errorMsg ? 'red' : 'black' }
                    }
                    value={this.state.userinfo}
                    onChange={e => this.setState({ userinfo: e.target.value })}
                    placeholder="请填写用户名/邮箱/主页地址"
                />
                <p className="text-red-600">
                    {this.state.errorMsg}
                </p>

                <div className="flex w-full justify-center">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
                        onClick={this.handleSubmit}
                    >
                        确认
                    </button>

                    {this.props.handleCancel
                        ? <button
                            className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
                            onClick={this.props.handleCancel}
                        >取消</button>
                        : null
                    }
                </div>

            </form>
        )
    }
}