//@ts-ignore
import { UAParser } from 'ua-parser-js';
import * as forage from './forage';

// getLocalEnvironment 获取当前设备环境
export function getLocalEnvironment() {
    const env = new UAParser().getResult();
    return `CPU: ${env.cpu.architecture};`
        + `OS: ${env.os.name} ${env.os.version};`
        + `Browser: ${env.browser.name}-${env.browser.version};`
        + `Engine: ${env.engine.name} ${env.engine.version};`
        + `Referrer: ${document.referrer};`;
}

export class Profile {
    constructor() {
        this.id = -1;
        this.email = "";
        this.name = '';
        this.maxload = 0;
        this.avatar = '';
        this.role = '';
        this.status = 'active';
        this.token = '';
        this.address = '';
        this.fingerprint = '';
        this.environment = '';
    }

    id: number
    email: string
    name: string
    avatar: string
    role: string
    token: string
    status: string
    address: string
    socketId?: string
    environment: string | null
    fingerprint: string | null
    maxload? : number

    save(data: any) {
        for (const key of ['id', 'name', 'avatar', 'socketId', 'role', 'address']) {
            // @ts-ignore
            if (data[key]) this[key] = data[key];
        }
        if (data && data.fingerprint) this.setfingerprint(data.fingerprint);
        return this;
    }

    setfingerprint(cert: string | null) {
        this.fingerprint = cert;
        forage.setLocalFingerprint(cert);
    }

    async sync(){
        this.environment = getLocalEnvironment();
        this.fingerprint = await forage.getLocalFingerprint();
        return this;
    }
}