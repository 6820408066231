import React from "react";
import { Mode, IMessage } from "../upchat-sdk/message";
import OpinionForm from './plugins/OpinionForm';
import UserInfoForm from "./plugins/UserInfoForm";
import FeedbackForm from "./plugins/FeedbackForm";
import AdvisoryTypeFrom from "./plugins/AdvisoryTypeForm";

interface Interface {
    message: IMessage
    imageMaxWidth?: string
    fontSize?: string
    handleSubmitOpinion?: any
    handleSubmitUserInfo?: any
    handleSubmitFeedback?: any
    handleSubmitAdvisoryType?: any
}

// matchURL 匹配出字符串中的 url 开始和结束位置
function matchURL(data: string) {
    const result = [];
    // eslint-disable-next-line
    const regExp = new RegExp("(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%$#_=]*)?", 'g');
    for (let i = regExp.exec(data); i != null; i = regExp.exec(data)) {
        result.push({ index: i.index, data: i[0] });
    }
    return result;
}

// PickURLElements 提取文本中的链接转化为 a 标签
function PickURLElements(data: string) {
    const dataUrl = matchURL(data);
    const renderElement = new Array<JSX.Element>();
    let p = 0;
    for (const i of dataUrl) {
        renderElement.push(
            <React.Fragment key={`fra-${i}`}>{data.slice(p, i.index)}</React.Fragment>,
            // eslint-disable-next-line
            <a key={`a-${i}`} className="underline" href={i.data} target="_blank">{i.data}</a>
        );
        p = i.index + i.data.length;
    }
    renderElement.push(<React.Fragment key={`fra-${p}`}> {data.slice(p)} </React.Fragment>);
    return renderElement;
}

function TextContent(props: Interface) {
    const className = "whitespace-pre-line break-all font-sans font-medium p-2";
    const deletedClassName = "line-through text-gray-500";
    return (
        <pre
            style={{ fontSize: props.fontSize }}
            className={props.message.data.deleted_at ? className + " " + deletedClassName : className}
        >{PickURLElements(props.message.data.content)}</pre>
    )
}

function ImageContent(props: Interface) {
    const className = "cursor-pointer bg-white scale-50 max-w-xs rounded";
    const deletedClassName = "opacity-25";
    const filename = props.message.data.content.split("/").slice(-1);
    return (
        <a href={props.message.data.content} download={filename} target="_blank">
            <img
                alt="img"
                className={props.message.data.deleted_at ? className + " " + deletedClassName : className}
                src={props.message.data.content}
                style={{
                    maxWidth: '400px',
                    minWidth: '40px',
                    minHeight: '40px',
                }}
            />
        </a>
    );
}

function FileContent(props: Interface) {
    return (
        <a href={props.message.data.content} download="file">
            <img className="bg-white" src="/media/images/download.png" width="120px" height="120px" alt="img" />
        </a>
    )
}

export default function BubbleContent(props: Interface) {
    // 插件信息
    const deletedClassName = "line-through text-gray-500";
    switch (props.message.data.mode) {
        case Mode.O_PLUGINS_INVITE:
            return <OpinionForm
                className={props.message.data.deleted_at ? deletedClassName : "border border-gray-300"}
                style={{ fontSize: props.fontSize }}
                handleSubmit={props.handleSubmitOpinion}
            />
        case Mode.O_PLUGINS_USERINFO:
            return <UserInfoForm
                className={props.message.data.deleted_at ? deletedClassName : "border border-gray-300"}
                style={{ fontSize: props.fontSize }}
                handleSubmit={props.handleSubmitUserInfo}
            />
        case Mode.O_PLUGINS_FEEDBACK:
            return <FeedbackForm
                className={props.message.data.deleted_at ? deletedClassName : "border border-gray-300"}
                style={{ fontSize: props.fontSize }}
                handleSubmit={props.handleSubmitFeedback}
            />
        case Mode.O_PLUGINS_ADVISORYTYPE:
            return <AdvisoryTypeFrom
                className={props.message.data.deleted_at ? deletedClassName : "border border-gray-300"}
                style={{ fontSize: props.fontSize }}
                handleSubmit={props.handleSubmitAdvisoryType}
            />
    }

    // 正常信息
    switch (props.message.data.content_type) {
        case 'text/plain':
            return <TextContent {...props} />;
        case 'image/gif':
        case 'image/png':
        case 'image/jpeg':
            return <ImageContent {...props} />;
        default:
            return <FileContent {...props} />;
    }
}