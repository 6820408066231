import yupoo from './yupoo';
import upyun from './upyun';
import houdeyun from './houdeyun';

const target = process.env.REACT_APP_TARGET;

export function getConfig() {
    switch(target) {
        case 'yupoo':
            return yupoo;
        case 'upyun':
            return upyun;
        case 'houdeyun':
            return houdeyun;
        default:
            throw new Error('not found env REACT_APP_TARGET');    
    }
}
