import axios from 'axios';

export class AudioPlayer {
    constructor() {
        const AudioContext = window.AudioContext || (window as any).webkitAudioContext || null

        this.audioBufferCache = null;
        this.audioContext = null;
        if (AudioContext) {
            this.audioContext = new AudioContext();
        }
    }

    private audioContext: AudioContext | null;
    private audioBufferCache: AudioBuffer | null;

    private async fetchaudioBuffer() {
        if (this.audioBufferCache == null) {
            const buffer = await axios
                .get("/media/audio/tick.mp3", { responseType: 'arraybuffer' })
                .then(res => res.data);
            this.audioBufferCache = await this.audioContext!.decodeAudioData(buffer);

        }
        return this.audioBufferCache!;
    }

    private async createSource() {
        if (!this.audioContext) return null;
        const source = this.audioContext.createBufferSource();
        source.buffer = await this.fetchaudioBuffer();
        source.connect(this.audioContext.destination);
        return source;
    }

    public async noisy() {
        if (!this.audioContext) return;
        await (await this.createSource())!.start()
        return await this.audioContext?.resume();
    }
}

