import React from "react";

const emojis = [
    { id: '微笑', native: '😃' },
    { id: '笑哭了', native: '😂' },
    { id: '吓到了', native: '😱' },
    { id: '得意', native: '😎' },
    { id: '流汗', native: '😓' },
    { id: '吐舌', native: '😜' },
    { id: '冷漠', native: '😐' },
    { id: '恶魔', native: '😈' },
    { id: '鬼', native: '👻' },
    { id: '马', native: '🐎' },
    { id: '鸭子', native: '🦆' },
    { id: '爱心', native: '💖' },
    { id: '枯萎', native: '🥀' },
    { id: '玫瑰', native: '🌹' }
]

interface IComponentProps {
    style?: React.CSSProperties
    handlePick: (emoji: string) => void
}


export default class EmojiPicker extends React.Component<IComponentProps> {

    render() {
        const emojiElements = emojis.map(v =>
            <span
                key={v.id}
                onClick={() => this.props.handlePick(v.native)}
                className="cursor-pointer hover:bg-gray-400 text-center mx-1 text-base"
            > {v.native}</span>
        )
        return (
            <div className="flex rounded text-xl emoji" style={this.props.style}>{emojiElements}</div>
        )
    }
}