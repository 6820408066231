import { Config } from './interface';

const config : Config = {
    header: {
        logo: 'favicon-yupoo.ico',
        content:'图片管家客服',
    },
    screen:{},
    serviceURL:'https://upchat-api.upyun.com',
    websocketURL: 'wss://upchat-api.upyun.com/ws/1',
    bulletin: {
        content: '尊敬的客户，感谢您使用图片管家客服。为保证服务及时高效，请您提供准确的账号或服务名、有效的联系方式',
        worktime:'工作时间: (9:00-18:00)',
        helper: [
            {
                content: '电话1：0571-81020203',
            },
            {
                content: '电话2：0571-81020204',
            },
            {
                content:'邮件：faq@yupoo-inc.com',
            },
            {
                content: '电话：0571-81020204'
            },
        ]
    }
}

export default config;