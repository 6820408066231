// Copyright 2020 The upchat. All rights reserved.
// 反馈建议表单

import React from 'react';

export interface IComponentState {
  description: string;
  userinfo: string;
  contact: string;
  files: File[];
  errorMsg: string | null;
}

interface IComponentProps {
  style?: React.CSSProperties;
  className?: string;
  handleSubmit?: (data: any) => void;
  handleCancel?: any;
}

// FeedbackForm 填写提交用户信息表单
export default class FeedbackForm extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      description: '',
      files: [],
      contact: '',
      userinfo: '',
      errorMsg: null,
    };
  }

  private handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ errorMsg: '' });

    if (!this.props.handleSubmit) {
      return;
    }

    if (!this.state.description) {
      this.setState({ errorMsg: '先填写问题描述及建议' });
      return;
    }
    this.props.handleSubmit({
      files: this.state.files,
      description: this.state.description,
      userinfo: this.state.userinfo,
      contact: this.state.contact,
    });
  };

  handleCancel = () => {
    this.setState({ files: [] });
    return this.props.handleCancel();
  };

  handleDeleteFile(index: number) {
    this.state.files.splice(index, 1);
    this.setState({
      files: this.state.files,
    });
  }

  render() {
    return (
      <form
        style={this.props.style}
        className={`bg-white p-4 w-full text-base ${this.props.className} text-gray-800`}
        onSubmit={(e) => e.preventDefault()}
      >
        <h1 className="text-xl font-bold my-2 text-gray-800 text-center">
          反馈建议
        </h1>

        <div>
          <label className="py-1">
            问题描述及建议<span className="text-red-600">*</span>
          </label>
          <textarea
            className="w-full h-20 border focus:outline-none focus:ring-2 focus:ring-purple-600 rounded p-2 resize-none"
            maxLength={200}
            style={{ borderColor: this.state.errorMsg ? 'red' : 'black' }}
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
            placeholder="请描述您要反馈的问题及建议"
          />
          <p className="text-red-600">{this.state.errorMsg}</p>
        </div>

        <div>
          <label className="py-1">上传图片/视频</label>
          <input
            id="feedbackfile"
            type="file"
            className="hidden"
            accept="image/png, image/jpeg, video/mp4"
            onChange={(e) => {
              if (!e.target.files || this.state.files.length >= 4) {
                return;
              }
              if (e.target.files[0].size > 1024 * 200 * 1000) {
                return;
              }
              const { files } = this.state;
              files.push(e.target.files[0]);
              this.setState({ files });
            }}
          />

          <div className="flex items-center text-gray-400">
            <label
              htmlFor="feedbackfile"
              className="border-2 border-gray-400 rounded p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <p className="px-4">图片小于2M，最多4张, 视频小于200MB</p>
          </div>

          <div>
            {this.state.files.map((f, i) => (
              <div
                key={f.name + i}
                className="flex content-center m-2 bg-gray-100"
              >
                <img
                  className="h-12 w-12"
                  src={URL.createObjectURL(f)}
                  alt=""
                />
                <div className="p-2 text-gray-600">
                  <p>
                    {f.name} (
                    <button
                      className="text-red-400 cursor-pointer"
                      onClick={() => this.handleDeleteFile(i)}
                    >
                      删除
                    </button>
                    )
                  </p>
                  <p className="text-xs">{f.size / 1000}KB</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="py-2">
          <label className="py-1">用户信息</label>
          <input
            className="block border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-600 p-2 w-full"
            placeholder="请填写用户名/邮箱/主页地址"
            onChange={(e) => this.setState({ userinfo: e.target.value })}
          />
        </div>

        <div className="py-2">
          <label className="py-1">联系方式</label>
          <input
            className="block border border-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-600 p-2 w-full"
            placeholder="请留下您的手机号 / 微信 / QQ"
            onChange={(e) => this.setState({ contact: e.target.value })}
          />
        </div>

        <div className="flex w-full justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
            onClick={this.handleSubmit}
          >
            确认
          </button>

          {this.props.handleCancel ? (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 m-2 rounded"
              onClick={this.handleCancel}
            >
              取消
            </button>
          ) : null}
        </div>
      </form>
    );
  }
}