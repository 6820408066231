import React from 'react';

export interface IComponentProps {
    className?: string
    content: string
    worktime: string
    helper: {
        content: string
        url?: string
        image?: string
    }[]
}

export default function Bulletin(props: IComponentProps) {
    return (
        <div className={"p-4 " + props.className || ''}>
            <h1 className="text-center text-sm text-blue- pb-4 c-primary">提示信息</h1>
            <div className="text-xs text-gray-600 tracking-normal leading-5">
                <p className="my-2">{props.content}</p>
                <p>如遇座席忙或没有客服在线，您可以通过以下方式获取使用帮助：</p>
                <ul className="ml-6 mx-2 list-disc">
                    {props.helper.map((v, i) => {
                      if (v.image) {
                        return (
                          <div className="pt-1">
                            <a  href='https://www.houdeyun.cn/stats/gpu?utm_source=upyunkf&utm_medium=Referral&utm_campaign=kefu' target='_blank'><img src={v.image}/></a>
                          </div>
                        )
                      }
                        return (
                            <li key={'help-' + i}>
                                {v.url
                                    ? <a className="text-blue-600" target='_blank' href={v.url}>{v.content}</a>
                                    : v.content}
                            </li>
                        )
                    })}
                </ul>
                <p>{props.worktime}</p>
                <p className="my-2 tracking-tight">注意：关闭本窗口将无法收到我们的回复，对话未结束前请勿关闭本窗口。</p>

                <div>
                    <h3 className='font-bold'>产品快讯</h3>
                    <ul className='list-inside tracking-tighter text-xs'>
                        <li>
                            <a className="text-blue-700 py-1 pl-1" target='_blank' href='https://www.upyun.com/products/ssl?utm_source=kefu&utm_medium=Referral&utm_campaign=adwz001'>🔥 泛域名 SSL 证书 0 元申请入口</a>
                        </li>
                        <li>
                            <a className="text-blue-700 py-1 pl-1" target='_blank' href='https://www.upyun.com/products/overseas?utm_source=kefu&utm_medium=Referral&utm_campaign=adwz002'>🔥 海外加速免费测试名额限时领取</a>
                        </li>
                    </ul>
                </div>
                <div className="pt-1">
                    <a  href='https://www.houdeyun.cn/stats/gpu?utm_source=upyunkf&utm_medium=Referral&utm_campaign=kefu' target='_blank'><img src='/media/images/advertise-gpu.png'/></a>
                </div>
            </div>
        </div>
    )
}