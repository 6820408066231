import React from 'react';
import Header from './components/Header';
import Bulletin from './components/Bulletin';
import Screen from './components/Screen';
import Editor from './components/Editor';
import EditorTools from './components/EditorTools';
import * as notify from './utils/notify';
import { getConfig } from './config';
import { UpchatClient, UpchatMessage } from './upchat-sdk';
import { MIME, Mode } from './upchat-sdk/message';
import * as forage from './upchat-sdk/forage';
interface IComponentState {
  code: string
  client: UpchatClient
  messages: UpchatMessage[]
  openEmojiPicker: boolean
  openFeedbackForm: boolean
  openOpinionForm: boolean
  openUserInfoForm: boolean
  openProblemForm: boolean
  hasOpinionExists: boolean
  showMobileTools: boolean
  hasAdvisoryTypeExists: boolean
}

export default class App extends React.Component<any, IComponentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: '',
      messages: [],

      openEmojiPicker: false,
      openFeedbackForm: false,
      openOpinionForm: false,
      openUserInfoForm: false,
      openProblemForm: false,
      showMobileTools: false,
      hasOpinionExists: false,
      hasAdvisoryTypeExists: false,

      client: new UpchatClient({
        websocketURL: getConfig().websocketURL,
        serviceURl: getConfig().serviceURL,
      }),
    }
  }

  componentDidMount() {

    this.state.client.connect()

    // @ts-ignore
    forage.getHistoryMessages().then((res: UpchatMessage[]) => {
      if (!res) return;
      this.state.messages.unshift(...res)
      this.forceUpdate()
    })

    // eslint-disable-next-line
    this.state.client.onMessage((type, message) => {
      this.state.messages.push(message);
      if (type === 'direct') notify.soundNoisy();
      if (message.data.mode === Mode.O_NORMAL) forage.appendHistoryMessages(message);
      this.forceUpdate();
    });

    this.state.client.onMessageDel((messageId) => {
      this.state.messages.forEach(v => {
        if (v.data.message_id === messageId) {
          v.data.deleted_at = new Date().toISOString();
          console.log(v);
        }
      });
      forage.removeHistoryMessage(messageId);
      this.forceUpdate()
    });
    this.state.client.subscribe(client => this.forceUpdate());
  }

  componentWillUnmount() {
    this.state.client.io?.close();
  }

  render() {
    return (
      <div id="root" className="h-screen flex flex-col justify-between">

        <Header
          className='app-hd'
          logo={getConfig().header.logo}
          serviceName={getConfig().header.content}
          schedule={this.state.client.connectStatus}
        />

        <div className="flex divide-x msg-out-box" style={{ flex: 1}}>
          <div className="flex-1 flex-1 flex flex-col bg-gray-50">

            <Screen
              className="flex-1 scrolling-auto overflow-y-scroll py-2"
              messages={this.state.messages.concat()}

              handleSubmitOpinion={data => {
                this.state.client.syscallSubmitOpinion(data);
                this.setState({
                  messages: this.state.messages.filter(v => v.data.mode !== Mode.O_PLUGINS_INVITE),
                })
              }}

              handleSubmitUserInfo={data => {
                this.state.client.syscallSubmitUserInfo(data);
                this.setState({
                  messages: this.state.messages.filter(v => v.data.mode !== Mode.O_PLUGINS_USERINFO),
                })
              }}

              handleSubmitFeedback={data => {
                this.state.client.syscallSubmitFeedback(data);
                this.setState({
                  messages: this.state.messages.filter(v => v.data.mode !== Mode.O_PLUGINS_FEEDBACK),
                })
              }}

              handleSubmitAdvisoryType={data => {
                if (this.state.hasAdvisoryTypeExists) {
                  return;
                }
                this.setState({ hasAdvisoryTypeExists: true });
                this.state.client.syscallSubmitAdvisoryType(data);
              }}
            />

            <div className={`msg-block ${this.state.showMobileTools ? 'is-tool' : ''}`}>
              <EditorTools
                openEmojiPicker={this.state.openEmojiPicker}
                toggleOpenEmojiPicker={() => this.setState({ openEmojiPicker: !this.state.openEmojiPicker })}

                openFeedbackForm={this.state.openFeedbackForm}
                toggleOpenFeedbackForm={() => this.setState({ openFeedbackForm: !this.state.openFeedbackForm })}

                openOpinionForm={this.state.openOpinionForm}
                toggleOpenOpinionForm={() => this.setState({ openOpinionForm: !this.state.openOpinionForm })}

                openUserInfoForm={this.state.openUserInfoForm}
                toggleOpenUserInfoForm={() => this.setState({ openUserInfoForm: !this.state.openUserInfoForm })}

                handleSubmitFile={this.state.client.sendFile}
                handlePick={s => this.setState({ code: this.state.code + s, openEmojiPicker: false })}

                openProblemForm={this.state.openProblemForm}
                toggleOpenProblemForm={()=>this.setState({ openProblemForm: !this.state.openProblemForm })}

                handleSubmitProblem={data => {
                  this.state.client.syscallSubmitProblem(data)
                  this.setState({ openProblemForm: false })
                }}

                handleSubmitOpinion={data => {
                  if (this.state.hasOpinionExists) return alert('您已经评价过了');
                  this.state.client.syscallSubmitOpinion(data);
                  this.setState({ hasOpinionExists: true, openOpinionForm: false });
                }}

                handleSubmitUserInfo={data => {
                  this.state.client.syscallSubmitUserInfo(data);
                  this.setState({ openUserInfoForm: false });
                }}

                handleSubmitFeedback={data => {
                  this.state.client.syscallSubmitFeedback(data);
                  this.setState({ openFeedbackForm: false });
                }}
              />

              <Editor
                code={this.state.code}
                showMobileTools={this.state.showMobileTools}
                handleSubmitFile={this.state.client.sendFile}
                handleChange={code => this.setState({ code })}
                toggleMobileTool={() => this.setState({ showMobileTools: !this.state.showMobileTools })}
                handleSubmit={code => this.state.client.send({ content_type: MIME["text/plain"], content: code })}
              />
            </div>
          </div>
          <div className="bg-white w-60 border-l hidden lg:block xl:block">

            <Bulletin
              content={getConfig().bulletin.content}
              helper={getConfig().bulletin.helper}
              worktime={getConfig().bulletin.worktime}
            />
          </div>
        </div>
      </div>
    )
  }
}
